import { useState } from 'react';
import faqData from './FAQData.json'

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>();


  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="w-full bg-black md:px-12 px-5 lg:py-20 text-white">
      <h1 className="md:text-[56px] text-4xl tregular mb-8 text-start">
        <span className="text-[#525252]">STRATEGIC</span> PLAN
      </h1>

      <div>
        {faqData.map((item, index) => (
          <div
            key={index}
            className="flex lg:flex-row flex-col items-start lg:items-center border-b border-gray-700 py-8"
          >
            <button
              className="tregular text-[#525252] text-start text-2xl hover:text-white transition flex-shrink-0 mr-6"
              onClick={() => handleClick(index)}
            >
              {item.title}
            </button>
            <div className={`${activeIndex === index ? '' : 'hidden'}`}>
              <p className="2xl:px-44 xl:px-20 lg:text-start text-left leading-8 text-[#525252] pt-4 lg:pt-0">
                {item.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQSection;
