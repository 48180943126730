import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import rankingPanelSliceReducer from './rankingPanel/RankingPanelSlice';
import nftRendererSliceReducer from './nftRenderer/nftRendererSlice';
import genesisNFTReducer from './genesisNFT/genesisNFTSlice';
import appStateReducer from './appState/appStateSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    rankingPanel: rankingPanelSliceReducer,
    nftRenderer: nftRendererSliceReducer,
    genesisNFT: genesisNFTReducer,
    appState: appStateReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
