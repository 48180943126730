import { useEffect, useMemo, useContext } from 'react';
import { Box, Flex, Text, Divider } from '@chakra-ui/react';
import { display_type } from '@/type';
import { setRankingData, setPoint, setInvitedTasks, calculateCompletionRate } from '@/state/rankingPanel/RankingPanelSlice';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import useReadScore from '@/libs/useReadScore'
import AccountContext from "@/context/account";
import useNFTData from '@/hooks/useNFTData';
import { convertToUSDTUnitsString } from '@/utils/web3Helper';

interface RankingData {
  twinMatrix: number;
  AlltwinMatrix: number;
  points: number;
  invitedTasks: number;
  completedTasks: number;
  completionRate: number;
}

interface RankingPanelProps {
  displayValues?: display_type[];
}

const RankingPanel = ({ displayValues = [] }: RankingPanelProps) => {
  const dispatch = useAppDispatch();
  const rankingData = useAppSelector((state) => state.rankingPanel);
  const { account, accountInfo } = useContext(AccountContext);
  const { data: rankingPoint = 0 } = useReadScore(account || '');
  const { taskData } = useNFTData();

  const newRankingData = useMemo(() => {
    const initialRankingData: RankingData = {
      twinMatrix: 0,
      AlltwinMatrix: 256,
      points: 0,
      invitedTasks: 0,
      completedTasks: 0,
      completionRate: 0,
    };
    const data = { ...initialRankingData };

    displayValues.forEach((value) => {
      if (value.data !== '00') {
        data.completedTasks += 1;
        data.twinMatrix += 1;
      }
    });

    return data;
  }, [displayValues]);

  useEffect(() => {
    dispatch(setRankingData(newRankingData));
    if (accountInfo) {
      dispatch(setPoint(convertToUSDTUnitsString(rankingPoint as number)));
    }
    if (accountInfo && taskData.length > 0) {
      // console.log('taskData', taskData);
      dispatch(setInvitedTasks(taskData.length));
    }
    dispatch(calculateCompletionRate());
  }, [newRankingData, rankingPoint, dispatch, accountInfo, taskData]);

  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-5" >
      <div className={" flex flex-col justify-center rounded-lg border border-[#27272A] shadow-sm p-4"}>
        <Text fontSize="sm">Twin Matrix</Text>
        <Flex align="center">
          <Text fontSize="4xl" fontWeight="bold">{rankingData.twinMatrix ?? 0}</Text>
          <Text fontSize="md">/{rankingData.AlltwinMatrix ?? 256}</Text>
        </Flex>
      </div>
      <div className={"flex flex-col justify-center rounded-lg border border-[#27272A] shadow-sm p-4"}>
        <Text fontSize="sm">Points</Text>
        <Text fontSize="4xl" fontWeight="bold">{rankingData.points ?? 0}</Text>
      </div>
      <div className={"flex flex-col justify-center rounded-lg border border-[#27272A] shadow-sm p-4"}>
        <Text fontSize="sm">Invited Tasks</Text>
        <Text fontSize="4xl" fontWeight="bold">{rankingData.invitedTasks ?? 0}</Text>
      </div>
      <div className={"flex flex-col justify-center rounded-lg border border-[#27272A] shadow-sm p-4"}>
        <Text fontSize="sm">Completed Tasks</Text>
        <Text fontSize="4xl" fontWeight="bold">{rankingData.completedTasks ?? 0}</Text>
      </div>
      <div className={"flex flex-col justify-center rounded-lg border border-[#27272A] shadow-sm p-4"}>
        <Text color="orange.400" fontSize="sm">Completed Rate</Text>
        <Text fontSize="4xl" fontWeight="bold" color="orange.400">{rankingData.completionRate}%</Text>
      </div>
    </div>
  );
};

export default RankingPanel;