import { useCallback, useEffect, useState, useContext } from 'react';
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import USDT_ABI from '@/abi/USDT_abi.json';
import SCORE_ABI from '@/abi/Twin3ScoreExchange_abi.json';
import {
  USDT_CONTRACT_ADDRESS,
  Twin3ScoreExchange_CONTRACT_ADDRESS,
} from '@/utils/constants';
import { useToast } from '@chakra-ui/react';
import AccountContext from '@/context/account';
import useReadScore from '@/libs/useReadScore';
import { convertToUSDTUnits } from '@/utils/web3Helper';
import { data_from_GET_me } from '@/type';

const useUpdateSBT = (
  userData: data_from_GET_me | undefined,
  amount: number | 1
) => {
  const { data: hash, writeContract } = useWriteContract();
  const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  // usestate set signature

  const [signature, setSignature] = useState<string>();
  const [hasUpdated, setHasUpdated] = useState(false);

  const { account } = useContext(AccountContext);
  const { refetch } = useReadScore(account || '');

  const toast = useToast();

  const submit = useCallback(
    async (signature: `0x${string}`) => {
      try {
        setHasUpdated(false);
        setSignature(signature);
        writeContract({
          abi: USDT_ABI,
          address: USDT_CONTRACT_ADDRESS,
          functionName: 'approve',
          args: [
            Twin3ScoreExchange_CONTRACT_ADDRESS,
            convertToUSDTUnits(amount),
          ],
        });
        // console.log('USDT', hash);
      } catch (error) {
        console.error('Error executing transactions:', error);
        toast({
          title: 'Contract submission failed!',
          status: 'error',
        });
      }
    },
    [writeContract, amount, toast, hash]
  );

  useEffect(() => {
    if (isConfirmed && !hasUpdated && userData) {
      setHasUpdated(true);
      writeContract({
        abi: SCORE_ABI,
        address: Twin3ScoreExchange_CONTRACT_ADDRESS,
        functionName: 'updateSBTWithERC20',
        args: [
          USDT_CONTRACT_ADDRESS,
          convertToUSDTUnits(amount),
          userData.token_id,
          `0x${userData.data}`,
          signature,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated, isConfirmed, userData]);

  useEffect(() => {
    if (hash && isConfirmed) {
      toast({
        title: 'Transaction Success',
        status: 'success',
      });
      refetch();
    }
  }, [hash, toast, isConfirmed, refetch]);

  return { submit };
};

export default useUpdateSBT;
