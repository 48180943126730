// src/pages/GenesisNFTPage.tsx
import {
  Box,
  Flex,
  Text,
  Grid,
  Link,
  VStack,
} from "@chakra-ui/react";
import TaskNavigator from "@/components/TaskNavigator";
import genesisNFT from "@/pages/maketplaceConfig.json";
import GenesisTicket from "@/pages/GenesisTicket";
import RankingPanel from "@/pages/RankingPanel";
import useNFTData from "@/hooks/useNFTData";
import { Twin3Genesis_CONTRACT_ADDRESS } from "@/utils/constants";
import Twin3Genesis_ABI from "@/abi/Twin3Genesis _abi.json";
import { useReadContracts } from "wagmi";
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '@/state/hooks';
import { setRemainingSupply } from "@/state/genesisNFT/genesisNFTSlice";


const GenesisNFTPage = () => {
  const { displayValues } = useNFTData();
  const dispatch = useAppDispatch();

  const SupplyContract = {
    address: Twin3Genesis_CONTRACT_ADDRESS,
    abi: Twin3Genesis_ABI,
  } as const;

  const { data: totalSupply, refetch: refetchTotalSupply } = useReadContracts({
    contracts: (() => {
      const contracts = [];
      for (let i = 0; i < 6; i++) {
        contracts.push({
          ...SupplyContract,
          functionName: "totalSupply",
          args: [i],
        });
      }
      return contracts;
    })() as Array<any>,
  });

  const { data: maxSupply } = useReadContracts({
    contracts: (() => {
      const contracts = [];
      for (let i = 0; i < 6; i++) {
        contracts.push({
          ...SupplyContract,
          functionName: "maxSupply",
          args: [i],
        });
      }
      return contracts;
    })() as Array<any>,
  })

  const nft_maxSupply = useMemo(() =>
    maxSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || []
    , [maxSupply]);

  const nft_totalSupply = useMemo(() =>
    totalSupply?.map((tier) =>
      parseInt((tier.result as number).toString().replace("n", ""))
    ) || []
    , [totalSupply]);

  useEffect(() => {
    const leftNFT: number[] = [];
    nft_totalSupply.forEach((supply, index) => {
      leftNFT.push(nft_maxSupply[index] - supply);
    })
    dispatch(setRemainingSupply(leftNFT));
    // console.log('leftNFT', leftNFT);
  }, [nft_totalSupply, nft_maxSupply, dispatch]);

  const nftTiers = genesisNFT;

  return (
    <Flex py={20} justify={'center'} gap={'80px'} >
      {/* left sidebar */}
      <TaskNavigator topParam={60} />
      {/* genesisNFTPage */}
      <Flex
        flexDirection="column"
        mb={20}
        maxW={"750px"}
        p={{ base: 2, md: 0 }}
      >
        <Flex gap={16} direction={{ base: "column", lg: "row" }} >
          <Box flex={3} maxW={1440} >
            <Box className={"rounded-lg border border-[#27272A] shadow-sm p-2 mb-4"}>
              <Text fontSize="4xl" fontWeight="bold" my={2}>Genesis NFT</Text>
              <Text mb={2}>
                Become a supporter with community influence and profit-sharing rights, limited to 6,666 copies.
                {" "}
                <Link
                  href="https://whitepaper.0xer.org"
                  target="_blank"
                  textDecoration="underline"
                >
                  more
                </Link>
              </Text>
            </Box>
            <RankingPanel displayValues={displayValues} />
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap={6}
              justifyItems={"center"}
              className={"rounded-lg border border-[#27272A] shadow-sm p-4 mt-4"}
            >
              {nftTiers.map((tier) => (
                <VStack className={"rounded-lg border border-[#27272A] shadow-sm p-4"}>
                  <GenesisTicket
                    id={tier.id}
                    name={tier.name}
                    src={tier.src}
                    points={tier.points}
                    refetchTotalSupply={refetchTotalSupply}
                  />
                </VStack>
              ))}
            </Grid>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GenesisNFTPage;